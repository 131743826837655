import React from 'react';
import axios from 'axios';

import GraphiQL from 'graphiql';
import { createClient } from 'graphql-ws/lib/client';
import { createGraphiQLFetcher } from '@graphiql/toolkit';

const subscriptionUrl = 'wss://myschema.com/graphql';

const fetcher = createGraphiQLFetcher({
  url: lgGraphQLServer() + lgFrontendToken(),
  legacyClient: new createClient(subscriptionUrl),
});


class LGGraphiQL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // REQUIRED:
      // `fetcher` must be provided in order for GraphiQL to operate
      fetcher: fetcher,

      // OPTIONAL PARAMETERS
      // GraphQL artifacts
      query: props.query,
      variables: '{ "itemId": "' + props.itemId + '" }',
      response: '',
 
      // GraphQL Schema
      // If `undefined` is provided, an introspection query is executed
      // using the fetcher.
      schema: undefined,
 
      // Useful to determine which operation to run
      // when there are multiple of them.
      operationName: null,
      storage: null,
      defaultQuery: props.default_query,
 
      // Custom Event Handlers
      onEditQuery: this.handleEditQuery.bind(this),
      onEditVariables: null,
      onEditOperationName: null,
      onToggleDocs: null,
      // GraphiQL automatically fills in leaf nodes when the query
      // does not provide them. Change this if your GraphQL Definitions
      // should behave differently than what's defined here:
      // (https://github.com/graphql/graphiql/blob/master/src/utility/fillLeafs.js#L75)
      getDefaultFieldNames: null,

      dirty: false
    };
  }
  componentDidMount() {
    this.docExplorerInit();
  }

  handleEditQuery(){
    let editor = this.graphiql.getQueryEditor();
    let currentQuery = editor.getValue();
    this.setState({ dirty: this.state.query != currentQuery });
  }

  saveQuery(currentQuery) {
    const item = {
      query: currentQuery,
      collection_type: this.props.collection_type,
      collection_id: this.props.collection_id,
      member_id: this.props.member_id,
      owner_id: this.props.member_id
    }
    this.setState({ dirty: false, query: currentQuery });
    axios.post('/api/v1/graphql_queries', { item })
    .then((response) => {
      $("#flash_message").noty({ type: 'success', text: 'Query saved successfully', timeout: 3000 });
    })
    .catch((error) => {
      $("#flash_message").noty({ type: 'error', text: 'Cannot save query' });
    })
  }

  handleClickPrettifyButton() {
    const editor = this.graphiql.getQueryEditor();
    const currentQuery = editor.getValue();
    const { parse, print } = require('graphql');
    const prettyText = print(parse(currentQuery));
    editor.setValue(prettyText);
  }

  handleClickSaveButton() {
    const g = this.graphiql;
    const editor = this.graphiql.getQueryEditor();
    const currentQuery = editor.getValue();
    this.saveQuery(currentQuery);
  }

  handleClickPreviewButton() {
    const g = this.graphiql;
    //const ve = g.variableEditorComponent;
    //const id = JSON.parse(ve.props.value).itemId;
    const id = this.props.itemId;
    const editor = g.getQueryEditor();
    const current_query = editor.getValue().replaceAll("\n", "");
    $.fancybox({
      parent: 'body',
      type: 'ajax',
      href: '/biocollections/graphql_preview?id=' + id + '&item_type=' + this.props.collection_type + '&query=' + encodeURI(current_query),
      closeBtn: false,
      padding: 0,
      afterShow: function(){
        ReactRailsUJS.mountComponents('.lg_fancybox_content');
        setTimeout(() => $.fancybox.update(), 500);
      }
    });
  }

  handleResetToDefaultButton() {
    const g = this.graphiql;
    const editor = g.getQueryEditor();
    if (confirm("Reset to default query? All changes will be lost")){
      editor.setValue(g.props.defaultQuery);
    }
  }

  handleClickUndoButton(){
    const editor = this.graphiql.getQueryEditor();
    editor.setValue(this.state.query);
    // this.setState({ dirty: false });
  }

  docExplorerInit() {
    const g = this.graphiql;
    if (g && g.docExplorerComponent){
      g.docExplorerComponent.handleSearch(this.props.collection_type === "Biocollections::Generic" ? 'GenericCollection' : this.props.collection_name);
    }
  }
 
  render() {
    return (
      <GraphiQL ref={c => { this.graphiql = c; }} {...this.state}>
        <GraphiQL.Logo>
          {this.props.collection_name} Query Builder
        </GraphiQL.Logo>
        <GraphiQL.Toolbar>
          <GraphiQL.Button
            onClick={this.handleClickPrettifyButton.bind(this)}
            label="Prettify"
            title="Prettify query"
          />
          <GraphiQL.Button
            onClick={this.handleClickPreviewButton.bind(this)}
            label="Preview"
            title="Preview show page"
          /> 
          { this.state.dirty && <GraphiQL.Button
            onClick={this.handleClickUndoButton.bind(this)}
            label="Undo last changes"
            title="Restore last saved query"
            />}
          <GraphiQL.Button
            onClick={this.handleResetToDefaultButton.bind(this)}
            label="Reset to default"
            title="Reset to default query - all changes will be lost"
          /> 
          <GraphiQL.Button
            onClick={this.handleClickSaveButton.bind(this)}
            label="Save"
            title="Save query"
            class="save_query_button"
          />
        </GraphiQL.Toolbar>

      </GraphiQL>
    );
  }
}

export default LGGraphiQL
