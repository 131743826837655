import React from 'react';

export default function LgcStepperStep({id, modifier, title, disabled, currentStepId, onClick}) {
  const handleClick = () => {
    if (disabled || id == currentStepId ) {
      console.log('disabled');
    } else {
      onClick(id)
    }
  }

  const disabledClass = (disabled) ? 'lgc-progress-steps__step--disabled' : '';

  return (
    <>
      <li
        className={`lgc-progress-steps__step lgc-progress-steps__step${modifier} ${disabledClass}`}
        onClick={handleClick}
        data-disabled={disabled}
      >
        <h3>
          {id}
        </h3>

        <small>
          {title}
        </small>
      </li>
    </>
  )
}
