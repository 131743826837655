import { isCanDeleteSigFlow, deleteSigFlow } from './LgcSfApiMethods';

const formatDateTime = (time) => {
  return new Date(time).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric", hour: "numeric", minute: "2-digit" });
};

const getMemberFullName = (members, memberId) => {
  let member = members.find(member => member.id === memberId);
  return `${member.first_name} ${member.last_name}`;
};

const displayFlashMessage = (type, text) => $("#flash_message").noty({ type: type, text: text });

const confirmationDialog = (text) => { return confirm(text) };

const isWitnessStep = (index) => { return index === -1 };

const totalSignersForStep = (signers) => {
  if (!Array.isArray(signers)) {
    return 1
  }
  const totalSigners = signers.reduce((sum, obj) => {
    if (obj.team === true && obj.members) {
      return sum + obj.members.length;
    } else {
      return sum + 1;
    }
  }, 0);
  return totalSigners;
};

const extractWitnesserIds = (witnessers, item) => {
  if (item.logic_id === 2) {
    return {
      members: [],
      teams: []
    }
  }
  let members = [];
  let teams = [];
  if (witnessers.length > 0) {
    witnessers.map((witnesser) => {
      if (witnesser.id.includes('member')) {
        const id = witnesser.id.split('member')[1];
        members.push(id)
      } else if (witnesser.id.includes('team')) {
        const id = witnesser.id.split('team')[1];
        teams.push(id)
      };
    });
  }

  let result = {
    members: members,
    teams: teams
  }
  return result
};

const cloneAndExtractWitnessers = (item, advanced) => {
  const clonedItem = { ...item };
  if (advanced) {
    clonedItem.steps.forEach((step) => {
      step.witnessers = extractWitnesserIds(step.witnessers, step);
    });
  }
  clonedItem.witnessers = extractWitnesserIds(clonedItem.witnessers, clonedItem);
  return clonedItem;
};

const checkDeleteStatus = async (flow) => {
  try {
    const res = await isCanDeleteSigFlow(flow.id);
    if (res.data.can_delete) {
      const answer = await confirmationDialog(I18n.t("signature_flow.delete_confirmation", { flow_name: flow.name }));
      if (!answer) return false;

      const deletedSuccessfully = await deleteSigFlow(flow.id);
      if (deletedSuccessfully.data.deleted_successfully) {
        displayFlashMessage('success', I18n.t("signature_flow.deleted_successfully", { flow_name: flow.name }));
        return true
      } else {
        displayFlashMessage('error', response.data.errors);
      }
    } else {
      displayFlashMessage('error', res.data.msg);
    }
  } catch (error) {
    displayFlashMessage('error', I18n.t("signature_flow.general_error"));
  }
};

const stepIsNotValid = (errors, currentStepIndex) => {
  // Currently displays only one error(witnessers).
  if (errors && Object.keys(errors).length > 0) {
    try {
      return errors['steps'][currentStepIndex].witnessers;
    } catch {
      return null
    }
  }
};

/**
 * uniteObjectsByPosition Groups an array of objects by their "position" and considers the "status" and "role_of_signers" properties.
 * Calculates the number of steps with status "completed" and the number of items with the same position value.
 *
 * @param {Array} inputArray - The array of objects to be grouped.
 * @returns {Array} An array of objects, each representing a unique position with calculated properties:
 *   - position: The unique position value.
 *   - roleMatch: True if all items at this position have the same "role_of_signers" value, otherwise false.
 *   - completedCount: The count of items with "completed" status at this position.
 *   - itemCount: The total number of items with the same position value.
/** */

function uniteObjectsByPosition(inputArray) {
  const outputArray = [];
  const positionMap = new Map();

  inputArray.forEach(item => {
    const position = item.position;
    const status = item.status;
    const role = item.role_of_signers;

    if (positionMap.has(position)) {
      if (status === "active" || positionMap.get(position).allActive) {
        positionMap.get(position).allActive = true;
      }
      if (status === "pending" || positionMap.get(position).allPending) {
        positionMap.get(position).allPending = true;
      }
      if (status === "completed") {
        positionMap.get(position).completedCount++;
      }
      if (role === positionMap.get(position).role) {
        positionMap.get(position).roleMatch = true;
      }
      positionMap.get(position).itemCount++;
    } else {
      positionMap.set(position, {
        position: position,
        role: role,
        completedCount: status === "completed" ? 1 : 0,
        itemCount: 1
      });
    }
  });

  positionMap.forEach(value => {
    outputArray.push(value);
  });
  return outputArray;
}

export {
  stepIsNotValid,
  cloneAndExtractWitnessers,
  totalSignersForStep,
  extractWitnesserIds,
  formatDateTime,
  getMemberFullName,
  displayFlashMessage,
  confirmationDialog,
  isWitnessStep,
  checkDeleteStatus,
  uniteObjectsByPosition
}
