import React from "react";
import LgcStepper from "../LgcStepperSF/LgcStepper";
import { uniteObjectsByPosition } from './Utils/LgcSfHelperMethods'

// LgcStepper cherry-picked from the Gibson assembly feature.

const LgcSfSignaturesProgress = ({ signatureFlow, signature_steps, all_members, current_step }) => {
  const steps = signature_steps
  const updatedSteps = steps.map((step) => ({
    ...step,
    status: current_step.id === step.id ? 'active' : step.signed_by ? 'completed' : 'pending'
  }));
  const uniteSteps = uniteObjectsByPosition(updatedSteps);

  return (
    <div className="lgc-step-progress-bar row" style={{ display: 'flex', margin: '0px', overflowX: 'scroll' }}>
      <LgcStepper
        steps={uniteSteps}
        all_members={all_members}
      />
    </div>
  )
};
export default LgcSfSignaturesProgress;
