import React, { useEffect } from 'react';
import GibsonIllustration from './GibsonIllustration.js';

import { useSelector,useDispatch } from 'react-redux';
import { selectCloningObject, updateCloningName,updateCloningDescription } from '../slices/cloneSlice';

const LgcCloneSettings = (props) => {
  const status = useSelector(state => state.clone.status);
  const cloningObject = useSelector(selectCloningObject);
  const dispatch = useDispatch();

  const handleNameChange = (event) => {
    dispatch(updateCloningName(event.target.value));
  };

  const handleDescriptionChange = (event) => {
    dispatch(updateCloningDescription( event.target.value));
  };

  const handleMethodChange = (event) => {
    props.setMethod(event.target.value);
  };

  const handleOptionChange = (event) => {
    props.setInsertToPlasmid(event.target.value === 'true');
  };

  const Instructions = () => {
    return (
      <>
        {<h1 className='item-title'>{I18n.t('molecular_biology_cloning.lgc_clone_settings')} </h1>}
      </>
    )
  }

  useEffect(()=>{
    props.handleInstructions(Instructions);
  },[]);

  useEffect(()=>{
    if(status === 'completed'){
      const ocw = new CustomEvent(`UpdateCloneId_${props.elementId}`, { detail: {cloningId:cloningObject.id,elementId:props.elementId}});
      document.dispatchEvent(ocw);
      dispatchEvent(ocw);
    }

  },[cloningObject]);

  return (
    <>
      {
        status == 'loading' ?
        <div className="flex-column centered" style={{flexGrow: '1'}}>Loading...</div>
      :
        <div className='lgc-clone-wizard-intro'>
          <div className='row'>
            <div className='col-md-4'>
              {/* Use react final form or similar for validations: https://final-form.org/docs/react-final-form/getting-started */}
              <form>
                <div className='form-group'>
                <label>
                  Cloning Name:
                </label>
                  <input data-lg-id= "clone_name_input" className="form-control" type="text" value={cloningObject.name} onChange={handleNameChange} />
                </div>
                <div className='form-group'>
                <label>
                  Cloning Description:
                </label>
                  <textarea data-lg-id= "clone_description_input" className="form-control" type="text" value={cloningObject.description} onChange={handleDescriptionChange} />
                </div>
                <div className='form-group'>
                <label>
                  Choose process:
                  </label>
                  <select data-lg-id= "clone_process_select" className='form-control' value={props.method} onChange={handleMethodChange}>
                    <option value="0">Gibson Assembly</option>
                    <option value="1" disabled>Batch Cloning</option>
                    <option value="2" disabled>Pipe Cloning</option>
                  </select>
                </div>
                <div className='form-group'>
                <div className='radio-control'>
                  <label className='radio-control__label'>
                    Assemble into Vector

                    <input data-lg-id= "assemble_into_vector" className='radio-control__input' type="radio" name="option1" value={true} checked={props.insertToPlasmid } onChange={handleOptionChange} />
                  </label>
                </div>
                <div className='radio-control'>
                  <label className='radio-control__label'>
                    Assemble Fragments

                    <input disabled data-lg-id= "assemble_fragments" className='radio-control__input' type="radio" name="option2" value={false} checked={!props.insertToPlasmid} onChange={handleOptionChange} />
                  </label>
                </div>
                </div>
              </form>
            </div>
            <div className='col-md-7 col-md-offset-1'>
              <div className='lgc-clone-wizard-banner'>
              <h1 data-lg-id= 'sub_title' className='lgc-clone-wizard-banner__title sub-title'>Gibson Assembly: Cloning Technique </h1>
              <p>
              Gibson Assembly created by Dr. Daniel Gibson joins multiple DNA fragments seamlessly in a single isothermal reaction through an enzyme cocktail, negating the need for restriction enzymes or ligases.
              </p>
              <GibsonIllustration></GibsonIllustration>
              <br/>
              <p>
              This innovation has transformed genetic engineering by facilitating the rapid and efficient synthesis of synthetic genes, genetic pathways, and whole genomes.
              </p>
              <p>
              Valued in synthetic biology and molecular biology, Gibson Assembly is known for its precision, speed, and versatility in assembling DNA constructs. However, there are some constraints: it is costlier than traditional methods due to the use of specific enzymes, and there’s a risk of unintended by-products if DNA fragments share homologous regions
              </p>
              <p>
              Additionally, Gibson Assembly is sensitive to the length and sequences of overlaps between fragments. Suboptimal overlaps may cause lower efficiency or assembly failure. Thus, meticulous design and optimization are crucial.
              </p>
              <p>
              For further reading, refer to the original article by Dr. Daniel Gibson:
                <a href='https://www.nature.com/articles/nmeth.1318' target="_blank">Enzymatic assembly of DNA molecules up to several hundred kilobases.</a>
              </p>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default LgcCloneSettings;
