import _ from 'lodash'
import React from 'react'
import { mountComponents } from 'react_ujs'
import ShowInnerTable from './ShowInnerTable'
import {htmlSafe, titelize} from '../utils/Utils'

const ShowInfoRow = ({ data, info }) => {   
  return _.keys(data).map((key, index) => {
    if (key !== 'customFields' && key !== '__typename' && data[key])
      if (_.isArray(data[key]))
        return (
          <tr key={"tr" + index}>
            <td>{titelize(key)}</td>
            <td>          
              <ShowInnerTable data={data[key]}></ShowInnerTable>    
            </td>
          </tr>
        )
      else
        if (_.isObject(data[key]))
          return (
            <tr key={"tr" + index}>
              <td>{titelize(key)}</td>
              <td> 
                <table className='info-table width100'>
                  <tbody>
                    <ShowInfoRow data={data[key]}></ShowInfoRow>
                  </tbody>
                </table>
              </td>
            </tr>                
          )
        else 
          return (
            <tr key={"tr" + index}>
              <td>{titelize(key)}</td>
              <td>{htmlSafe(data, key, info)}</td>
            </tr>
          )
  })
}

export default ShowInfoRow
