import React, { useContext, useState, useCallback } from 'react';
import { LgcSfContext } from '../../LgcSfContext';

const LgcSfStep = ({ step, index, showStepContent, type = "review", previewMode }) => {
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const { signatureFlow, setSignatureFlow } = useContext(LgcSfContext);
  const { itemApplied } = useContext(LgcSfContext);
  const { currentStepIndex, setCurrentStepIndex } = useContext(LgcSfContext);

  const { errors } = useContext(LgcSfContext);

  const style = {
    cursor: itemApplied ? 'none' : 'move',
    pointerEvents: itemApplied ? 'none' : 'auto'
  }

  const stepContentHandler = () => {
    showStepContent(index)
  }

  const deleteStep = useCallback(() => {
    const id = step.id
    let stepsSize = 0
    setSignatureFlow(prevItem => {
      const updatedSteps = prevItem.steps
        .filter(step => step.id !== id) // Filter out the step with the matching id
        .map((step, index) => ({ ...step, position: index })); // Update the positions of the remaining steps
      stepsSize = updatedSteps.length
      return {
        ...prevItem,
        steps: updatedSteps
      };
    });
    if (stepsSize > 0) {
      setCurrentStepIndex(0)
    } else {
      setCurrentStepIndex(-1)
    }
  }, [setCurrentStepIndex, signatureFlow]);

  // CANCEL LOGIC
  const handleCancel = () => {
    setIsCancelConfirmationOpen(true);
  };

  const handleCancelConfirmation = () => {
    deleteStep()
    setIsCancelConfirmationOpen(false);
  };

  const handleCancelCancel = () => {
    setIsCancelConfirmationOpen(false);
  };

  const membersListToString = (data) => {
    const names = data.map((item) => item.name);
    return names.join(", ");
  };
  let stepErrorValue;
  if (errors && errors.steps && typeof step.position === 'number' && errors.steps[step.position] !== undefined) {
    stepErrorValue = errors.steps[step.position];
  } else {
    stepErrorValue = undefined;
  }

  return (
    <div data-lg-id={`signature_flow_form_step_${step.position}`} style={style} className={`lgc-dragabble-step${index === currentStepIndex ? ' lgc-dragabble-step--active' : ''}  ${stepErrorValue && 'lgc-dragabble-step--has-errors'}`}>
      <div className='row row-no-gutters'>
      <div className='col-md-12'>
          {type === "review" &&
            <div className='row row-no-gutters'>
              <div className='col-xs-6' onClick={stepContentHandler}>
                <i style={{color: 'var(--color-text-ui)'}} className="fa fa-bars" aria-hidden="true"></i>
              </div>
              <div className='col-xs-6 text-right'>
                {!previewMode &&
                  <>
                    {(!isCancelConfirmationOpen) &&
                      <>
                        <i onClick={handleCancel} className='fa-solid fa-trash-can'></i>
                      </>
                    }
                    {/* Cancel confirmation dialog */}
                    {isCancelConfirmationOpen && (
                      <div>
                        <i style={{ cursor: 'pointer',color: 'var(--color-text-ui)',paddingLeft: '4px', paddingRight:'4px' }} onClick={handleCancelCancel} className='fa fa-times'></i>
                        <i style={{ cursor: 'pointer', color: 'var(--success-color)',paddingLeft: '4px', paddingRight:'4px' }} onClick={handleCancelConfirmation} className='fa fa-check'></i>
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
          }
        </div>

        <div className='col-md-12 text-center' onClick={stepContentHandler}>
            {type === "review" ?
              <>
                <h4 className='lgc-dragabble-step__title'>{step.role_of_signers}</h4>
                <h6>{`(step${step.position + 1})`}</h6>
              </>
              :
              <h4 className='lgc-dragabble-step__title-mandatory'>{I18n.t("signature_flow.steps.witness_step_title")}</h4>
            }
            <>
              {previewMode &&
                <>
                  {type === "review" ?
                    <>
                      <p>This step requires {step.num_signatures_required} signatures and can be signed by</p>
                      {step.logic_id == 1 && <p>{membersListToString(step.witnessers)}</p>}
                      {(step.logic_id == 2 || step.logic_id == 3) && <p>all the members that are part of the project</p>}
                    </>
                    :
                    <>
                      <p>This is a final and mandatory step</p>
                      {signatureFlow.logic_id == 1 && <p>can be witness by <br/>{membersListToString(signatureFlow.witnessers)}</p>}
                      {(signatureFlow.logic_id == 2 || signatureFlow.logic_id == 3) && <p>It should be performed by anyone with witnessing permission</p>}
                    </>
                  }
                </>
              }
            </>
        </div>
      </div>
    </div >
  )
};

export default LgcSfStep;

